<template>
  <ModalWrapper @close="closeAction">
    <!-- Head -->
    <template #head>
      <h1 class="title">Создание отдела</h1>
    </template>

    <!-- Body -->
    <template #default>
      <div class="status-add pos-r z0 grid ai-fs">
        <BaseLoad v-if="isLoad" class="pos-a grid jc-c z99999 white-transparent" />

        <form class="p-20 grid ggap-20" id="form-add">
          <FormInput
            class="w-100"
            label="Название"
            placeholder="Название"
            v-model="formData.name"
            @keydown.enter.prevent
            required
          />

          <div class="form-item department__users">
            <label class="form-item__label">Выберите пользователей</label>

            <div class="department__users-list grid ggap-10">
              <UserSearch :users="formData.users" @setUser="setItem" />

              <!-- Item -->
              <div v-if="formData.users && formData.users.length" class="box overflow-h">
                <Draggable v-model="formData.users" item-key="id" ghost-class="ghost" handle=".handle">
                  <template #item="{ element, index }">
                    <UserItem :index="index" :user="element" @remove="removeItem" />
                  </template>
                </Draggable>
              </div>

              <BaseAlert v-else type="yellow">В отделе нет пользователей</BaseAlert>
            </div>
          </div>
        </form>
      </div>
    </template>

    <!-- Foot -->
    <template #foot>
      <div class="flex ai-c jc-sb ggap-20 h-100">
        <button class="btn grey" @click="closeAction">Отмена</button>
        <button class="btn primary" @click="submitAction">
          <BaseIcon class="ic-16 white" icon="check-circle" />
          Отправить
        </button>
      </div>
    </template>
  </ModalWrapper>
</template>

<script setup>
import Draggable from 'vuedraggable'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { BaseAlert, ModalWrapper, BaseLoad, BaseIcon, FormInput } from '@/components'
import UserItem from './components/UserItem.vue'
import UserSearch from './components/UserSearch.vue'
import { isInvalidForm, $busEmit } from '@/plugins'
import departments from '@/api/modules/departments'

// Data
const store = useStore()
const router = useRouter()
const isLoad = ref(false)
const formData = ref({
  name: '',
  users: []
})

// Created
store.dispatch('GET_USERS')

// Methods
function setItem(user) {
  const index = formData.value.users.findIndex((el) => el.id === user.id)
  if (index === -1) formData.value.users.push(user)
  else formData.value.users.splice(index, 1)
}
function removeItem(id) {
  const index = formData.value.users.findIndex((el) => el.id === id)
  formData.value.users.splice(index, 1)
}
function closeAction() {
  router.replace('/settings/departments')
}
function submitAction() {
  if (isInvalidForm('form-add')) return

  isLoad.value = true
  const payload = {
    name: formData.value.name,
    users: formData.value?.users.map((el, index) => {
      return {
        id: el.id,
        index
      }
    })
  }
  departments
    .create(payload)
    .then(async () => {
      await store.dispatch('helpers/GET_DEPARTMENTS')
      isLoad.value = false
      closeAction()
      $busEmit('setToast', {
        type: 'green',
        icon: 'check-circle',
        message: 'Отдел успешно создан, можете в любое время отредактировать его или удалить.'
      })
    })
    .finally(() => (isLoad.value = false))
}
</script>

<style lang="scss" scoped>
.status-add {
  width: 500px;
  min-height: 160px;
}
</style>
