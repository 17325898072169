<template>
  <div class="users-item grid gtc-1-auto ggap-10">
    <!-- User -->
    <div class="users-item__user flex ai-c ggap-10 p-10">
      <div class="users-item__counter t-black marker jc-c flex ai-c">{{ index + 1 }}</div>
      <div class="cover ava-30">
        <img :src="user.avatar || require('@/assets/img/ava.svg')" />
      </div>
      <div class="grid">
        <div class="title">{{ user.text }}</div>
        <small class="fs-12 t-grey-dark">{{ user.position || 'Без должности' }}</small>
      </div>
    </div>

    <!-- Btns -->
    <div class="flex h-100">
      <button class="users-item__btn flex ai-c jc-c" type="button" @click.prevent="emits('remove', user.id)">
        <BaseIcon class="ic-16 red" icon="delete" />
      </button>
      <button class="users-item__btn handle flex ai-c jc-c" type="button">
        <BaseIcon class="ic-16 black" icon="menu" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs } from 'vue'
import { BaseIcon } from '@/components'

// Emits
const emits = defineEmits(['remove'])

// Props
const props = defineProps(['index', 'user'])

// Data
const { index, user } = toRefs(props)
</script>

<style lang="scss" scoped>
.users-item {
  border-bottom: var(--b1);

  &:hover {
    background-color: var(--grey-l);
  }

  &:last-child {
    border: 0;
  }

  &__counter {
    width: 24px;
  }

  &__btn {
    border: 0;
    background-color: transparent;
    height: 100%;
    width: 50px;

    &:hover {
      background-color: var(--grey);
    }
    &:active {
      background-color: var(--grey-d);
    }
  }

  &.ghost {
    background-color: var(--primary-el);
  }

  &.ghost &__user,
  &.ghost &__btn {
    opacity: 0;
  }
}
</style>
