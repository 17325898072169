<template>
  <div
    class="form-item__input sm search grid gtc-auto-1-auto ai-c ggap-5 box p-5"
    :class="{ focused: isOpen }"
    :data-btn="uniqId"
    v-click-outside="closeAction"
    @click="toggleAction"
  >
    <!-- Input -->
    <div class="search-icon flex ai-c jc-c">
      <BaseLoad v-if="isLoad" class="rel sm" />
      <BaseIcon v-else class="ic-16 primary" icon="search" />
    </div>
    <input type="text" class="search-input" placeholder="Добавить человека" ref="searchInput" v-model="searchText" />
    <button v-if="searchText" class="btn sm rounded cube-20" type="button" @click.stop.prevent="clearAction">
      <BaseIcon class="ic-14 black" icon="close" />
    </button>

    <!-- Ddown -->
    <teleport to="body">
      <div v-if="isOpen" class="search-ddown box grid overflow-a p-5" :data-body="uniqId" @click.stop>
        <template v-if="items && items.length">
          <a
            href="#"
            v-for="item of items"
            :key="item.id"
            class="search-ddown__item flex ai-c ggap-10 p-5 pr-10"
            :class="{ active: isHas(item.id) }"
            type="button"
            @click.stop.prevent="setItem(item)"
          >
            <div class="cover ava-30">
              <img :src="item.avatar || require('@/assets/img/ava.svg')" />
            </div>
            <div class="grid">
              <div>{{ item.text }}</div>
              <small class="fs-12 t-grey-dark">{{ item.position || 'Без должности' }}</small>
            </div>
          </a>
        </template>
        <div v-else class="pt-10 pb-10 pl-20 pr-20 t-red">Ничего не найдено</div>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { debounce } from 'lodash'
import { useStore } from 'vuex'
import { defineEmits, defineProps, toRefs, ref, getCurrentInstance, watch, computed } from 'vue'
import { BaseIcon, BaseLoad } from '@/components'
import { $busOn, calcCoords } from '@/plugins'

// Emits
const emits = defineEmits(['setUser'])

// Props
const props = defineProps(['users'])

// Data
const { users } = toRefs(props)
const store = useStore()
const uid = getCurrentInstance().uid
const uniqId = 'search-user-' + uid
const searchInput = ref()
const searchText = ref('')
const isLoad = ref(false)
const isOpen = ref(false)

// Computed
const items = computed(() => store.getters.users || [])

// Watch
watch(isOpen, (val) => {
  if (val) {
    searchInput.value.focus()
    calcCoords(uniqId, 'ltr')
  }
})
watch(
  searchText,
  debounce((val) => {
    searchUsers()
    if (val) isOpen.value = true
  }, 1000)
)

// Created
$busOn('scrollAction', () => {
  if (isOpen.value) isOpen.value = false
})

// Methods
function toggleAction() {
  isOpen.value = !isOpen.value
}
function closeAction() {
  isOpen.value = false
}
function clearAction() {
  searchText.value = ''
  searchInput.value.focus()
}
async function searchUsers() {
  isLoad.value = true
  await store.dispatch('GET_USERS', searchText.value)
  calcCoords(uniqId, 'ltr')
  isLoad.value = false
}
async function setItem(item) {
  emits('setUser', item)
  searchText.value = ''
  isOpen.value = false
}
function isHas(id) {
  return users.value?.findIndex((el) => el.id === id) !== -1
}
</script>

<style lang="scss" scoped>
.search {
  &-icon {
    width: 30px;
    height: 30px;
  }

  &-input {
    border: 0;
    background-color: transparent;
  }

  &-ddown {
    position: fixed;
    z-index: 2001;
    box-shadow: var(--box-shadow);
    max-height: 200px;
    grid-gap: 2px;

    &__item {
      text-align: left;
      text-decoration: none;
      color: var(--text);
      border-radius: var(--br);

      &:hover {
        background-color: var(--grey-l);
      }
      &.active {
        background-color: var(--primary-el);
      }
      &.static {
        background-color: var(--secondary);
        color: #fff;
        pointer-events: none;
        cursor: not-allowed;

        small {
          color: #fff !important;
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
